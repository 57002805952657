<ion-tabs (ionTabsDidChange)="setCurrentTab($event)">
  <ion-tab-bar
    class="ion-no-border"
    slot="bottom"
    #bar
    [ngStyle]="{display: showTabs ? 'flex': 'none'}"
  >
    <ion-tab-button
      tab="assistant"
      [layout]="isAssitantActiveTab ? 'icon-top' : 'label-hide'"
    >
      <ion-icon name="compass-outline"></ion-icon>
      <ion-label>Assistant</ion-label>
    </ion-tab-button>

    <ion-tab-button
      tab="my"
      [layout]="isLoadsActiveTab ? 'icon-top' : 'label-hide'"
    >
      <ion-icon name="cube-outline"></ion-icon>
      <ion-label>Transports</ion-label>
    </ion-tab-button>

    <ion-tab-button
      tab="profile"
      [layout]="isAccountActiveTab ? 'icon-top' : 'label-hide'"
    >
      <ion-icon name="person-circle-outline"></ion-icon>
      <ion-label>Compte</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
