import { Component, OnInit } from '@angular/core';
import { AuthQuery, AuthService } from '@etrucking/iam/state';
import { take, skipWhile } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';

import { LocalNotifications } from '@capacitor/local-notifications';
import * as Sentry from '@sentry/angular';

/**
 *
 *
 * @export
 * @class AppComponent
 */
@Component({
  selector: 'etrucking-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   * Creates an instance of AppComponent.
   * @param {AuthService} auth
   * @param {SwUpdate} swUpdates
   * @param {ToastController} toastController
   * @memberof AppComponent
   */
  constructor(
    private auth: AuthService,
    private authQuery: AuthQuery,
    public swUpdates: SwUpdate,
    public toastController: ToastController
  ) {
    //this.auth.signOut();
  }

  ngOnInit() {
    this.checkForUpdates();
    setTimeout(() => {

      LocalNotifications.requestPermissions().then(() => {

        LocalNotifications.schedule({
          notifications: [
            {
              title: 'Title',
              body: 'Body',
              id: 1,
              schedule: {
                at: new Date(Date.now() + 1000 * 60), // in a minute
              },
              actionTypeId: '',
              extra: null,
            },
          ],
        });

      }).catch(error => {
        throw new Error(error.message);
      })

     // throw new Error('For sentry 221');
    }, 2000);
  }
  /**
   *
   *
   * @memberof AppComponent
   */
  checkForUpdates() {
    Sentry.addBreadcrumb({
      category: "service worker",
      message: "checkForUpdates",
      level: Sentry.Severity.Info,
    });

    this.swUpdates.available.subscribe((event) => {
      Sentry.addBreadcrumb({
        category: "service worker",
        message: `Update available: $ {event.current}`,
        level: Sentry.Severity.Info,
      });
      console.log('New update available');
      this.notifyUser();
    });
  }
  /**
   *
   *
   * @memberof AppComponent
   */
  async notifyUser() {
    const toast = await this.toastController.create({
      message: 'Mise à jour disponible',
      position: 'bottom',
      color: 'dark',
      buttons: [
        {
          side: 'start',
          text: 'Installer',
          handler: () => {
            console.log('Updating to latest version.');
            this.swUpdates
              .activateUpdate()
              .then(() => {
                Sentry.addBreadcrumb({
                  category: "service worker",
                  message: `User installed update`,
                  level: Sentry.Severity.Info,
                });
                document.location.reload()
              });
          },
        },
      ],
    });

    await toast.present();
  }
}
