import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment/frontend';
import { Logger } from '@etrucking/shared/utils/logger';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import '@angular/common/locales/global/fr';

(async (env) => {
  try {

    Sentry.init({
      dsn: "https://25b412b1e3a549ce83db22f587f9a69c@o1014878.ingest.sentry.io/5980132",
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ["localhost", "https://yourserver.io/api"],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],
      environment: env.production ? 'production' : 'development',
    
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
    
    if (env.production) {
      enableProdMode();
      Logger.enableProductionMode();
    }

    await platformBrowserDynamic().bootstrapModule(AppModule);
    console.log(`${environment.appName} bootstrapped`)
  } catch (err) {
    console.error(err);
  }
})(environment);
