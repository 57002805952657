import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { isArray } from '@datorama/akita';
import { omit, valuesIn } from 'lodash';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  map,
  share,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { BaseComponent } from './base';
import { isEqual } from '@etrucking/shared/utils/data';

/**
 *
 *
 * @export
 * @abstract
 * @class BaseListWidget
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @template T
 */
@Directive()
export abstract class BaseListWidget<T>
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  /**
   *
   *
   * @protected
   * @type {string[]}
   * @memberof BaseListWidget
   */
  protected omittedSearchValues: string[] = [];
  /**
   *
   *
   * @memberof BaseListWidget
   */
  initialized = false;
  /**
   *
   *
   * @memberof BaseListWidget
   */
  @Input() hasMore = true;
  /**
   *
   *
   * @type {number}
   * @memberof BaseListWidget
   */
  @Input() currentPage!: number;
  /**
   *
   *
   * @type {number}
   * @memberof BaseListWidget
  /**
   *
   *
   * @memberof BaseListWidget
   */
  @Input() itemsPerPage = 5;
  /**
   *
   *
   * @type {number}
   * @memberof BaseListWidget
   */
  @Input() maxPages!: number;
  /**
   *
   *
   * @memberof BaseListWidget
   */
  @Input() loading = true;
  /**
   *
   *
   * @type {ElementRef}
   * @memberof BaseListWidget
   */
  @ViewChild('itemsContainer') itemsContainerRef!: ElementRef;

  /**
   *
   *
   * @memberof BaseListWidget
   */
  @Input() set data(value: T[] | null) {
    this.datasource$.next([...(value && isArray(value) ? value : [])]);
    this.logger.debug('Datasource changed:', value);
  }
  /**
   *
   *
   * @type {EventEmitter<string>}
   * @memberof BaseListWidget
   */
  @Output() filter: EventEmitter<string> = new EventEmitter();
  /**
   *
   *
   * @type {EventEmitter<void>}
   * @memberof BaseListWidget
   */
  @Output() fetchMore: EventEmitter<void> = new EventEmitter();
  /**
   *
   *
   * @type {EventEmitter<string>}
   * @memberof BaseListWidget
   */
  @Output() viewItem: EventEmitter<T> = new EventEmitter();
  /**
   *
   *
   * @type {EventEmitter<string>}
   * @memberof BaseListWidget
   */
  @Output() editItem: EventEmitter<T> = new EventEmitter();
  /**
   *
   *
   * @type {EventEmitter<string>}
   * @memberof BaseListWidget
   */
  @Output() deleteItem: EventEmitter<T> = new EventEmitter();

  /**
   *
   *
   * @memberof BaseListWidget
   */
  readonly datasource$ = new Subject<T[]>();
  /**
   *
   *
   * @memberof BaseListWidget
   */
  readonly searchTerm$ = new BehaviorSubject<string>('');

  /**
   *
   *
   * @memberof BaseListWidget
   */
  readonly filters$ = new BehaviorSubject<ListFilter[]>([]);

  /**
   *
   *
   * @memberof BaseListWidget
   */
  readonly items$ = new BehaviorSubject<T[]>([]);
  /**
   *
   *
   * @memberof BaseListWidget
   */
  readonly filteredItems$ = combineLatest([
    this.datasource$,
    this.searchTerm$,
    this.filters$,
  ]).pipe(
    debounceTime(100),
    catchError((e) => {
      this.logger.error(e);
      return of([]);
    }),
    map(([datasource, searchTerm, filters]) => {
      let results: T[] = [...datasource];

      if (searchTerm) {
        results = results.filter((item) => {
          const omittedSearchValues = [
            'id',
            'createdAt',
            'updatedAt',
            ...this.omittedSearchValues,
          ];
          const i = omit<any>(item, omittedSearchValues);
          const values = valuesIn(i);

          return JSON.stringify(values)
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        });
        this.logger.debug('searchTerm:', searchTerm);
      }

      if (filters.length) {
        this.logger.debug('el to filter:', results);
        results = results.filter((el) =>
          filters.every(
            (filter) =>
              this.getProperty(el, filter.name as any) === filter.value
          )
        );
        this.logger.debug('filters:', filters);
      }

      return [...results];
    }),
    tap((items) => {
      this.runChangeDetection();
      this.logger.debug('filteredItems$', items);
    })
  );
  /**
   *
   *
   * @memberof BaseListWidget
   */
  readonly loading$ = new BehaviorSubject<boolean>(false);

  itemMinWidth = new BehaviorSubject<string>('');

  /**
   * Creates an instance of BaseListWidget.
   * @param {ChangeDetectorRef} changeDetector
   * @param {Renderer2} renderer
   * @memberof BaseListWidget
   */
  constructor(
    private changeDetector: ChangeDetectorRef,
    private renderer: Renderer2,
    private zone: NgZone
  ) {
    super(changeDetector);
  }
  /**
   *
   *
   * @memberof BaseListWidget
   */
  ngOnInit(): void {
    this.items$.pipe(takeUntil(this.destroy$), delay(500)).subscribe(() => {
      this.loading = false;
      this.runChangeDetection();
    });

    this.datasource$.pipe(takeUntil(this.destroy$)).subscribe((datasource) => {
      if (!this.initialized && Array.isArray(datasource)) {
        this.initialized = true;
        this.runChangeDetection();
      }
    });
  }
  /**
   *
   *
   * @memberof BaseListWidget
   */
  ngAfterViewInit(): void {
    if (this.itemsContainerRef) {
      const el = this.itemsContainerRef.nativeElement;
      const width = el.getBoundingClientRect().width - 48;
      const minWidth = `calc(${width / 4}px - 16px)`;
      this.itemMinWidth.next(minWidth);
    }
  }
  /**
   *
   *
   * @param {*} event
   * @memberof BaseListWidget
   */
  onSearch(event: any) {
    const term = typeof event === 'string' ? event : event.target.value;
    this.searchTerm$.next(term);
    this.logger.debug('Search term changed:', term);
  }
  /**
   *
   *
   * @param {*} event
   * @memberof BaseListWidget
   */
  onFilter(event: any) {
    const filter = event;
    this.filters$.next(filter);
    this.logger.debug('filters changed:', filter);
  }
  /**
   *
   *
   * @param {Array<T>} pageOfItems
   * @memberof BaseListWidget
   */
  onChangePage(pageOfItems: Array<T>) {
    this.loading = true;
    this.items$.next(pageOfItems);
    this.runChangeDetection();
    this.logger.debug('Pages collection items changed:', pageOfItems);
  }
  /**
   *
   *
   * @private
   * @template T
   * @template K
   * @param {T} obj
   * @param {K} key
   * @return {*}
   * @memberof BaseListWidget
   */
  private getProperty<T, K extends keyof T>(obj: T, key: K) {
    const value = obj[key];

    if (value === undefined || value === null) {
      throw 'Property doesnt exists on Element';
    }

    return value;
  }
}

export type ListMatcher<T> = (item: T) => boolean;
/**
 *
 *
 * @export
 * @interface ListFilter
 */
export interface ListFilter {
  name: string;
  value: any;
}
