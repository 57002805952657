import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '@etrucking/shared/uikit/core';
import { filter, takeUntil } from 'rxjs/operators';
import { TabsService } from './tabs.service';

@Component({
  selector: 'etrucking-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabsPage extends BaseComponent implements OnInit {
  protected name = 'TabsPage';
  showTabs = true; // <-- show tabs by default
  hideTabBarPages = ['new-group'];
  routeParamPages: string[] = ['product-details'];

  constructor(
    protected cd: ChangeDetectorRef,
    private router: Router,
    private tabsService: TabsService
  ) {
    super(cd);
  }

  activeTab = 'assistant';

  ngOnInit() {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((e: any) => {
        this.logger.debug(e);
        const urlArray: string[] = e.url.split('/');
        const pageUrlParent = urlArray[urlArray.length - 2];

        const pageUrl = urlArray[urlArray.length - 1];

        const page = pageUrl.split('?')[0];

        const isDetailsPage = urlArray.length > 2;

        const hideParamPage =
          this.routeParamPages.indexOf(pageUrlParent) > -1 &&
          !isNaN(Number(page));

        const shouldHide =
          isDetailsPage ||
          this.hideTabBarPages.indexOf(page) > -1 ||
          hideParamPage;

        this.logger.debug(
          urlArray,
          pageUrlParent,
          pageUrl,
          hideParamPage,
          shouldHide
        );

        try {
          this.showTabs = !shouldHide;
          //setTimeout(() => (shouldHide ? this.hideTabs() : this.showTabs()), 300);
        } catch (err) {
          this.logger.error(err);
        }
        // }
      });
  }

  get isAssitantActiveTab() {
    return this.activeTab === 'assistant';
  }

  get isLoadsActiveTab() {
    return this.activeTab === 'my';
  }

  get isAccountActiveTab() {
    return this.activeTab === 'profile';
  }

  setCurrentTab(e: any) {
    this.activeTab = e.tab;
  }
}
