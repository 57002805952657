import {
  CollectionConfig,
  FireAuthService,
  getCustomClaims,
} from 'akita-ng-fire';
import { combineLatest, from, NEVER } from 'rxjs';
import {
  catchError,
  delay,
  pluck,
  skipWhile,
  startWith,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { filterNil, resetStores } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Role, Session, User } from '@etrucking/iam/core';
import { Logger } from '@etrucking/shared/utils/logger';

import { OrganizationsService } from '../organizations';
import { ProfilesService } from '../profiles';
import { UsersService } from '../users';
import { AuthQuery } from './auth.query';
import { AuthState, AuthStore } from './auth.store';
import * as Sentry from '@sentry/angular';

const log = new Logger('AuthService');

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'profiles' })
export class AuthService extends FireAuthService<AuthState> {
  constructor(
    store: AuthStore,
    private routerQuery: RouterQuery,
    private orgsService: OrganizationsService,
    private usersService: UsersService,
    private profilesService: ProfilesService,
    private router: Router,
    private authQuery: AuthQuery
  ) {
    super(store);

    this.initAuth();
  }

  logout(): void {
    this.signOut();
  }

  initAuth() {
    this.auth.onAuthStateChanged((user: firebase.User | null) => {
      const { session } = this.store.getValue();
      log.debug('initAuth() => User is', session);

      if (user) {
        log.debug('initAuth() => User', user);

        Sentry.configureScope((scope) => {
          scope.setUser({
            email: user.email as string,
            id: user.uid as string,
            username: user.displayName as string,
          });
        });

        this.usersService
          .syncDoc({ id: user.uid })
          .pipe(
            tap(({ role, organizationId }) => {
              this.store.update({
                role,
                organizationId,
                session: Session.Authenticated,
              });
            }),
            switchMap((u) => this.syncUserStores()),
            take(1)
          )
          .subscribe();
      } else {
        if (session === Session.Authenticated) {
          this.signOut();
        }
      }
    });
  }

  protected onSignin({ user }: firebase.auth.UserCredential) {
    const nextUrl = this.routerQuery.getQueryParams()?.nextUrl as string;

    log.debug('onSignin() => nextUrl', nextUrl);

    this.authQuery.session$
      .pipe(
        skipWhile((session) => session !== Session.Authenticated),
        take(1)
      )
      .subscribe((session) => {
        log.debug(session, this.store.getValue());
        this.router.navigateByUrl((nextUrl as string) ?? '/');
      });
  }

  protected onSignout() {
    resetStores({ exclude: ['ui'] });
    Sentry.configureScope((scope) => scope.setUser(null));
    this.router.navigateByUrl('/iam/signin');
  }

  protected selectRoles(user: any) {
    return this.db
      .doc<Role>(`roles/${this.store.getValue().role}`)
      .valueChanges();
  }

  private syncUserStores() {
    return this.sync().pipe(
      filterNil,
      switchMap(([user, profile, claims]) => {
        return combineLatest([this.syncUserOrg()]);
      }),
      take(1)
    );
  }

  private syncUserOrg() {
    return this.orgsService.syncDoc({
      id: this.store.getValue().organizationId,
    });
  }
}
