import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { akitaConfig, persistState } from '@datorama/akita';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '@environment/frontend';
import { APP_NAME } from '@etrucking/shared/uikit/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import * as Sentry from '@sentry/angular';
import { debounceTime } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabsPageModule } from './tabs/tabs.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
    }),
    environment.pluginModules,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    AkitaNgRouterStoreModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    TabsPageModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        Sentry.configureScope((scope) => {
          //scope.setTag('role', 'DRIVER');
          scope.setExtra('role', 'DRIVER');
        });
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_NAME,
      useValue: 'e-Trucking',
    },
  
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Optional() @SkipSelf() parentModule: AppModule) {
    persistState({
      include: ['auth', 'ui.sidenavExtended'],
    });
    akitaConfig({ resettable: true });

    // Import guard
    if (parentModule) {
      throw new Error(`
        ${parentModule} has already been loaded.
        Import SharedAppShellModule module in the AppModule only.
      `);
    }
  }
}
