import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs/tabs.page';
import { WelcomeGuard } from './welcome.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/my',
    pathMatch: 'full',
  },
  {
    path: 'iam',
    loadChildren: () =>
      import('@etrucking/iam/shell-driver').then(
        (module) => module.IamShellAuthDriverModule
      ),
  },
  {
    path: '',
    canActivate: [WelcomeGuard],
    component: TabsPage,
    children: [
      {
        path: 'assistant',
        canActivate: [WelcomeGuard],
        loadChildren: () =>
          import('@etrucking/dispatches/shell-driver').then(
            (m) => m.DriverAssistantShellModule
          ),
      },
      {
        path: 'my',
        canActivate: [WelcomeGuard],
        loadChildren: () =>
          import('@etrucking/dispatches/shell-driver').then(
            (m) => m.DriverDispatchesShellModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('@etrucking/iam/shell-driver').then(
            (module) => module.IamShellProfileDriverModule
          ),
      },
    ],
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./welcome/welcome.module').then((m) => m.WelcomeModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
