import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

/**
 *
 *
 * @export
 * @class WelcomeGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class WelcomeGuard implements CanActivate {
  /**
   * Creates an instance of WelcomeGuard.
   * @param {Router} router
   * @memberof WelcomeGuard
   */
  constructor(private router: Router) {}
  /**
   *
   *
   * @return {*}  {boolean}
   * @memberof WelcomeGuard
   */
  canActivate(): boolean {
    const isWelcomeComplete = localStorage.getItem('etrk-welcome-complete');

    if (!!isWelcomeComplete === false) {
      this.router.navigate(['welcome']);
    }

    return !!isWelcomeComplete;
  }
}
