/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Logger } from '@etrucking/shared/utils/logger';
import { NgFormsManager } from '@ngneat/forms-manager';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { ModalRef } from '@etrucking/shared/uikit/modal';
import { isFunction } from 'lodash';

const _isEqual = function (value: any, other: any) {
  // Get the value type
  const type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  const valueLen =
    type === '[object Array]' ? value.length : Object.keys(value).length;
  const otherLen =
    type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  const compare = function (item1: any, item2: any) {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!_isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }

    return true;
  };

  // Compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (const key in value) {
      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

export interface Entity {
  createdAt?: any;
  id?: any;
}

/**
 * BaseForm Directive
 *
 * @export
 * @abstract
 * @class BaseForm
 * @implements {OnDestroy}
 * @template I
 * @template O
 */
@Directive()
export abstract class BaseForm<I, O> implements OnDestroy {
  #initialValue!: I;
  /**
   *
   *
   * @memberof BaseForm
   */
  @Input() set data(value: any) {
    if (value) {
      this.updateFormValue(value);
    }
  }
  /**
   *
   *
   * @memberof BaseForm
   */
  @Input() set options(value: any) {
    if (value) {
      this.optionChanges?.next(value);
    }
  }

  /**
   *
   *
   * @memberof BaseForm
   */
  @Input() set submitted(value: boolean | null) {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!value) {
      this._submitted = value;
    }
  }
  /**
   *
   *
   * @type {('add' | 'quick-add' | 'edit')}
   * @memberof BaseForm
   */
  @Input() mode: 'add' | 'quick-add' | 'edit' = 'add';
  /**
   *
   *
   * @type {EventEmitter<string>}
   * @memberof BaseForm
   */
  @Output() formRegister!: EventEmitter<string>;
  /**
   *
   *
   * @type {EventEmitter<O>}
   * @memberof BaseForm
   */
  @Output() formSubmit!: EventEmitter<O>;
  /**
   *
   *
   * @type {EventEmitter<O>}
   * @memberof BaseForm
   */
  @Output() formSave!: EventEmitter<O>;
  /**
   *
   *
   * @type {EventEmitter<string>}
   * @memberof BaseForm
   */
  @Output() formDelete!: EventEmitter<string>;
  /**
   *
   *
   * @type {EventEmitter<void>}
   * @memberof BaseForm
   */
  @Output() formCancel!: EventEmitter<void>;

  protected abstract name: string;
  protected formID!: string;

  private _logger!: Logger;
  private _unsubscribe!: Subject<void>;
  private _submitted = false;

  #initialized = false;
  #addModelInitialized = false;
  #editModelInitialized = false;
  /**
   *
   *
   * @readonly
   * @memberof BaseForm
   */
  get isEditMode() {
    return this.mode === 'edit';
  }
  /**
   *
   *
   * @readonly
   * @memberof BaseForm
   */
  get isAddMode() {
    return this.mode === 'add';
  }
  /**
   *
   *
   * @readonly
   * @memberof BaseForm
   */
  get isQuickAddMode() {
    return this.mode === 'quick-add';
  }

  dirty$!: Observable<boolean>;
  model!: I;
  optionsMap!: { [key: string]: { label: string; value: string }[] };
  form!: FormGroup;
  canSubmit$!: Observable<boolean>;
  canSubmitChanges!: Observable<boolean>;
  canDeleteChanges!: Observable<boolean>;
  optionChanges!: BehaviorSubject<{
    [key: string]: { label: string; value: string }[];
  }>;
  /**
   *
   *
   * @protected
   * @memberof BaseForm
   */
  protected idKey = 'id';

  /**
   *
   *
   * @readonly
   * @protected
   * @memberof BaseForm
   */
  protected get logger() {
    if (!this._logger) {
      this._logger = new Logger(this.name);
    }

    return this._logger;
  }

  /**
   *
   *
   * @readonly
   * @protected
   * @memberof BaseForm
   */
  protected get unsubscribe() {
    if (!this._unsubscribe) {
      this._unsubscribe = new Subject();
    }

    return this._unsubscribe;
  }
  /**
   *
   *
   * @readonly
   * @protected
   * @memberof BaseForm
   */
  protected get dirty() {
    return JSON.stringify(this.model) !== JSON.stringify(this.form?.value);
  }
  /**
   * Creates an instance of BaseForm.
   * @param {FormBuilder} fb
   * @param {NgFormsManager} formsManager
   * @param {ChangeDetectorRef} cd
   * @param {NgZone} zone
   * @memberof BaseForm
   */
  constructor(
    protected fb: FormBuilder,
    protected formsManager: NgFormsManager,
    protected cd: ChangeDetectorRef,
    protected zone: NgZone
  ) {
    this.formSubmit = new EventEmitter<O>();
    this.formCancel = new EventEmitter<void>();
    this.formSave = new EventEmitter<O>();
    this.formDelete = new EventEmitter<string>();
    this.optionChanges = new BehaviorSubject<{
      [key: string]: { label: string; value: string }[];
    }>({});
  }

  /**
   *
   *
   * @protected
   * @param {(string | null | undefined)} [id]
   * @param {{
   *       persistState?: boolean;
   *       debounceTime?: number;
   *       arrControlFactory?: any;
   *       withInitialValue?: boolean;
   *     }} [options]
   * @memberof BaseForm
   */
  protected connect(
    id?: string | null | undefined,
    options: {
      persistState?: boolean;
      debounceTime?: number;
      arrControlFactory?: any;
      withInitialValue?: boolean;
    } = {
      persistState: true,
      withInitialValue: true,
    }
  ): void {
    this.formID = id || `etrk.forms.${this.name}.${this.mode}`;

    this.formsManager.upsert(this.formID, this.form, { ...options });

    this.#initialValue = this.formsManager.getInitialValue<I>(this.formID) as I;

    this.canSubmitChanges = this.formsManager
      .initialValueChanged(this.formID)
      ?.pipe(
        delay(0),
        tap((isDirty) => {
          this.logger.debug(
            'canSubmitChanges isDirty | form.vali',
            isDirty,
            this.form
          );
        }),
        map((isDirty) => isDirty && this.form.valid),
        tap((status) => {
          this.logger.debug('canSubmitChanges', status);
          this.cd.detectChanges();
        }),
        startWith(false)
      );

    this.canDeleteChanges = this.formsManager.valueChanges(this.formID)?.pipe(
      delay(0),
      map((value) => value && value[this.idKey]),
      tap(() => {
        this.cd.detectChanges();
      }),
      startWith(false)
    );

    this.formsManager
      .valueChanges(this.formID)
      .pipe(
        takeUntil(this.unsubscribe),
        tap((value) => {})
      )
      .subscribe((value) => {
        const isPristine = _isEqual(value, this.#initialValue);
        this.logger.debug(
          'form.valueChanges() -> Initial & current values are the same',
          isPristine
        );

        // this.logger.debug(
        //   'form.valueChanges() -> Initial ',
        //   JSON.stringify(this.#initialValue)
        // );
        // this.logger.debug(
        //   'form.valueChanges() -> Current',
        //   JSON.stringify(value)
        // );

        if (isPristine) {
          this.formsManager.markAsPristine(this.formID, { emitEvent: true });
        } else {
          this.formsManager.markAsDirty(this.formID, { emitEvent: true });
          this.formsManager.markAsTouched(this.formID, { emitEvent: true });
        }

        setTimeout(() => {
          this.cd.detectChanges();
        }, 200);
        this.logger.debug('form.valueChanges', this.form);
      });

    this.optionChanges
      .pipe(
        takeUntil(this.unsubscribe),
        tap((o) =>
          setTimeout(() => {
            this.cd.detectChanges();
            this.logger.debug('OPTIONS CHANGE', o);
          }, 200)
        )
      )
      .subscribe();

    this.logger.debug('connect() -> initial value', this.#initialValue);
  }
  /**
   *
   *
   * @protected
   * @memberof BaseForm
   */
  protected disconnect(): void {
    this.logger.debug(`${this.formID} disconnect()`);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.formsManager.unsubscribe(this.formID);

    if (this._submitted || this.mode === 'edit') {
      this.formsManager.clear(this.formID);
    }
  }
  /**
   *
   *
   * @memberof BaseForm
   */
  delete() {
    this.formDelete?.emit(this.form.getRawValue());
  }
  /**
   *
   *
   * @memberof BaseForm
   */
  cancel() {
    this.formCancel?.emit();
  }
  /**
   *
   *
   * @param {*} [value]
   * @memberof BaseForm
   */
  save(value?: any) {
    if (value) {
      this.formSave?.emit(value);
    } else if (this.form?.valid) {
      this.formSave?.emit(this.form.getRawValue());
      this._submitted = true;
    } else {
      throw `Form <${this.formID}> cannot be saved`;
    }
  }

  /**
   *
   *
   * @param {*} [value]
   * @memberof BaseForm
   */
  submit(value?: any) {
    if (value) {
      this.formSubmit?.emit(value);
    } else if (this.form?.valid) {
      this.formSubmit?.emit(this.form.getRawValue());
      this._submitted = true;
    } else {
      throw `Form <${this.formID}> cannot be submitted`;
    }
  }
  /**
   * Updates the form value
   *
   * @private
   * @param {*} data
   * @memberof BaseForm
   */
  protected updateFormValue(data: any) {
    this.model = { ...data };
    this.zone.run(() => {
      if (this.mode === 'add') {
        this.updateAddFormValue(data);
      } else {
        this.updateEditFormValue(data);
      }

      this.cd.detectChanges();
    });
  }

  /**
   *
   *
   * @private
   * @param {*} data
   * @memberof BaseForm
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private updateAddFormValue(data: any) {
    if (this.#initialized) {
      this.formsManager.patchValue(this.formID, { ...this.model });
    } else {
      this.formsManager.setInitialValue(this.formID, {
        ...this.formsManager.getInitialValue(this.formID),
        ...this.model,
      });

      this.formsManager.markAsPristine(this.formID);
      this.formsManager.markAsUntouched(this.formID);
      this.#initialized = true;
    }
  }

  private updateEditFormValue(data: any) {
    if (_isEqual(this.#initialValue, data)) return;

    if (this.#initialized) {
      this.logger.debug(
        'updateEditFormValue() -> Initialized, will now patch value',
        this.model
      );

      if (!this.form.touched) {
        this.formsManager.setInitialValue(this.formID, {
          ...this.form.value,
          ...this.model,
        });

        this.#initialValue = this.formsManager.getInitialValue(
          this.formID
        ) as any;
        this.formsManager.patchValue(this.formID, {
          ...this.#initialValue,
          ...this.model,
        });
        this.formsManager.markAsPristine(this.formID, { emitEvent: true });
        this.formsManager.markAsUntouched(this.formID, { emitEvent: true });
        this.logger.debug(
          'updateEditFormValue() -> Form not touched, initial value updated',
          this.formsManager.getInitialValue(this.formID)
        );
      } else {
        this.formsManager.patchValue(this.formID, { ...this.model });
      }
    } else {
      this.logger.debug(
        'updateEditFormValue() -> Not Initialized, will verify model data'
      );

      if (data[this.idKey]) {
        this.logger.debug(
          'updateEditFormValue() -> Model is ready, will update initial value'
        );

        this.#initialValue = this.formsManager.getInitialValue(
          this.formID
        ) as any;

        this.formsManager.setInitialValue(this.formID, {
          ...this.#initialValue,
          ...this.model,
        });

        this.#initialValue = this.formsManager.getInitialValue(
          this.formID
        ) as any;

        this.logger.debug(
          'updateEditFormValue() -> Initial value / Model',
          this.#initialValue,
          this.model
        );

        this.formsManager.patchValue(this.formID, { ...this.#initialValue });
        this.formsManager.markAsPristine(this.formID, { emitEvent: true });
        this.formsManager.markAsUntouched(this.formID, { emitEvent: true });
        this.cd.detectChanges();

        this.#initialized = true;
      } else {
        this.logger.debug(
          'updateEditFormValue() -> Model is not ready yet...[skip]'
        );
      }
    }
  }

  /**
   *
   *
   * @memberof BaseForm
   */
  ngOnDestroy(): void {
    setTimeout(() => this.disconnect(), 1000);
  }
}

@Directive()
export abstract class BaseModalForm<I, O> extends BaseForm<I, O> {
  constructor(
    protected fb: FormBuilder,
    protected formsManager: NgFormsManager,
    protected cd: ChangeDetectorRef,
    protected zone: NgZone,
    protected ref: ModalRef
  ) {
    super(fb, formsManager, cd, zone);
  }

  submit() {
    super.submit();
    this.ref.close(this.form.value);
  }

  cancel() {
    this.ref.close();
    super.cancel();
  }

  protected connect(
    id?: string | null | undefined,
    config?: {
      persistState?: boolean;
      debounceTime?: number;
      arrControlFactory?: any;
      withInitialValue?: boolean;
    }
  ) {
    const { model, mode, options } = this.ref.data || {};

    if (mode) this.mode = mode;

    if (options && isFunction(options.subscribe)) {
      options
        .pipe(
          takeUntil(this.unsubscribe),
          tap((o: any) => this.optionChanges.next(o))
        )
        .subscribe();
    }

    if (model) {
      this.model = model;
      this.form.reset(model);
    }

    super.connect(id, config);
  }
}

export interface HashMap<T = any> {
  [key: string]: T;
}

/*  private updateFormValue(data: any) {
  const model = {} as any;

  for (const prop of Object.keys(this.form.controls)) {
    if (data.hasOwnProperty(prop)) {
      model[prop] = data[prop];
    }
  }

  this.zone.run(() => {

    if (!this.#initialized) {
      this.#initialized = true;
    }

    this.model = { ...model };

    this.formsManager.setInitialValue(this.formID, { ...this.model });
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.cd.markForCheck();
  });

  if (!this.#initialized) {
    this.#initialized = true;
  }
} */
