// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';

import { Environment } from './interface';

export const environment: Environment = {
  appName: 'e-trucking',
  production: false,
  serviceWorker: false,
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyDwJ2GZK30DDzmNCJrDIbyd5dXOMlobiL0',
    authDomain: 'tamf-e-trucking-dev.firebaseapp.com',
    databaseURL: 'https://tamf-e-trucking-dev.firebaseio.com',
    projectId: 'tamf-e-trucking-dev',
    storageBucket: 'tamf-e-trucking-dev.appspot.com',
    messagingSenderId: '554716293551',
    appId: '1:554716293551:web:61c0ebf57e7f593d7f275a',
    measurementId: 'G-XCN5XFMRYY',
  },
  pluginModules: [AkitaNgDevtools.forRoot()],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
